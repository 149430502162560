export const ROUTE_HOME = 'home';
export const ROUTE_LOGOUT_SUCCESS = '#logout=success';
export const ROUTE_STARTPAGE = '/home';
export const ROUTE_CUSTOMER_REGISTRATION = 'customer-registration';

// Pagination
export const PAGE_SIZE = 10;

// API
export const API_ROUTE_COMPANY = 'unternehmen/search';
export const API_ROUTE_SEARCH_BERATER = 'kundenberater/search';

export function getApiUrl(apiUrl: string, route: string) {
  return `${apiUrl}/v1/ikp/berater/${route}`;
}
