import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiComponentsModule } from '@mwe/ui/components';
import { UiCommonsModule } from '@mwe/ui/commons';
import { EnvironmentService, ProfileService } from '@mwe/services';
import { TranslateService } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  imports: [RouterModule, UiComponentsModule, UiCommonsModule, FontAwesomeModule, NgIf],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [ProfileService, EnvironmentService],
})
export class AppComponent {
  constructor(public translate: TranslateService) {
    translate.setDefaultLang('de');
    translate.addLangs(['de', 'en']);
    translate.use('de');
  }
}
