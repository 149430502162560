import { Environment } from './model/environment.model';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://apim-psp-backend-dev.azure-api.net/api',
  keycloakUrl: 'https://ca-psp-keycloak-dev.mangoriver-998e02b9.westeurope.azurecontainerapps.io',
  keycloakRealm: 'wienenergie',
  keycloakClient: 'client-meinwienenergie-public',
  baseUrl: 'https://ikpberaterfrontenddev.kinsta.cloud',
  staticContentUrl: 'https://test-static.wienenergie.at/projects/premiumserviceportal/config',
};
