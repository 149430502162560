import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'lib-ikp-header-logo',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  template: `<img ngSrc="assets/icon/header-logo.svg" alt="Premium Service Portal Logo" class="logo" width="232" height="32" priority />`,
})
export class HeaderLogoComponent {}
